
import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { Axios } from '../../../config';

// Action pour mettre à jour les informations d'audit en temps réel
// export const updateAuditInfo = createAction('configuration/updateAuditInfo');

export const auditInfo = createAsyncThunk('info/fetchAudit', async ({ siteId }) => {
  try {
    let url = `/audit/auditPhp`;
    if ( siteId) {
      // If the user is a Client, siteId is required to be added
      url += `?siteId=${siteId}`;
    }
      const res = await Axios.get(url);
      return res.data;
  } catch (error) {
    throw Error(JSON.stringify(error.response.data));
  }
});




const initialState = {
  isSuccessFetchingAudit: false,
  isErrorFetchingAudit: false,
  isFetchingAuditInfoData: false,
  errorMessage: '',
  auditInfoData: null,
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: { clearConfigurationData:  () => initialState},


 
  extraReducers: (builder) => {
    builder
      .addCase(auditInfo.pending, (state) => {
        state.isFetchingAuditInfoData = true;
        state.isErrorFetchingAudit = false;
        state.isSuccessFetchingAudit = false;
        state.auditInfoData = null; // Réinitialiser les données avant de charger les nouvelles
      })
      .addCase(auditInfo.fulfilled, (state, { payload }) => {
        state.auditInfoData = payload;
        state.isFetchingAuditInfoData = false;
        state.isSuccessFetchingAudit = true;
        state.isErrorFetchingAudit = false;
      })
      .addCase(auditInfo.rejected, (state, { error }) => {
        state.isFetchingAuditInfoData = false;
        state.isErrorFetchingAudit = true;
        state.isSuccessFetchingAudit = false;
        state.errorMessage = error.message;
        state.auditInfoData = null;
      })
      // .addCase(updateAuditInfo, (state, { payload }) => {
      //   state.auditInfoData = payload; // Traitement des mises à jour en temps réel
      //   state.isSuccessFetchingAudit = true;
      // });
  },
});

export const { clearConfigurationData } = configurationSlice.actions;
export default configurationSlice.reducer;
