import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Axios } from "../../../config";

export const fetchModuleById  = createAsyncThunk('info/fetchModule', async ({id,siteId},{ rejectWithValue }) => {

    try {
      let url = `/module/module/${id}`;
      if ( siteId) {
        // If the user is a Client, siteId is required to be added
        url += `?siteId=${siteId}`;
      }
        const res = await Axios.get(url);
        return res.data;
      

    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  });
export const fetchOptionByModuleId  = createAsyncThunk('info/fetchOption', async ({id,siteId},{ rejectWithValue }) => {

    try {
      let url = `/module/options/${id}?`;
      if(siteId){
        url += `?siteId=${siteId}`;

      }
      const res = await Axios.get(url);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  });
  export const toggleOption = createAsyncThunk(
    'module/toggleOption',
    async ({ optionId, siteId }) => {
      try {
        let res = await Axios.post(`/module/toggle-option?siteId=${siteId}`, {
          _id: optionId,
        });
        return res.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );
  
  export const saveSetting = createAsyncThunk('module/saveSetting', async ({ _id, value, siteId }, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`/module/save-setting?siteId=${siteId}`, { _id, value });
      return response.data.data; // Suppose that the server returns the data inside a "data" field.
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
});

  const   initialState= {
    isSuccess: false,
    isSuccessSettings: false,
    isError: false,
    isFetching: false,
    isFetchingOptions: false,
    isFetchingModule:false,
    options:null,
    optionsId:null,
   modules:null,
   settings:null,
   settingsSite:null
  }
  export const modulesSlice = createSlice({
    name: 'modulesSlice',
    initialState,
    reducers: { clearModulesData:  () => initialState},
    extraReducers: (builder) => {
      builder
        .addCase(fetchModuleById.pending, (state) => {
          state.isFetchingModule= true;
          // state.count=0;
          state.isSuccessSettings = false;
          state.isError = false;
          state.isSuccess = false;})
      
        .addCase(fetchModuleById.fulfilled, (state, { payload }) => {
          // console.log("payload",payload)
          state.isFetchingModule = false;
          state.modules = payload.module;
          state.options=payload.options;
          state.settings=payload.settings;
         state.isSuccess=true;
         state.isError=false
          })
          .addCase(fetchModuleById.rejected, (state, { payload }) => {
            state.isFetchingModule = false;
            state.isError = true;
            state.isSuccess = false;
            state.isSuccessSettings = false;
          })
        .addCase(fetchOptionByModuleId.pending, (state) => {
          state.isFetching = true;
          // state.count=0;
          state.isSuccessSettings = false;
          state.isError = false;
          state.isSuccess = false;})
      
        .addCase(fetchOptionByModuleId.fulfilled, (state, { payload }) => {
          // console.log("payload",payload)
          state.loading = false;
          state.optionsId=payload.options;
         state.isSuccess=true;
         state.isError=false
          })
          .addCase(fetchOptionByModuleId.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
         
            state.isSuccess = false;
            state.isSuccessSettings = false;
          })
          .addCase(toggleOption.pending, (state) => {
            state.isFetchingOptions = true;
            state.isSuccessOptions = false;
            state.isErrorOptions = false;
        })
        .addCase(toggleOption.rejected, (state,{ payload }) => {
            state.isFetchingOptions = false;
            state.isErrorOption = true;
            state.isSuccessOption = false;
            state.errorMessage = payload.error.message;
        })
        
            .addCase(toggleOption.fulfilled, (state, { payload }) => {
              state.isFetchingOptions = false;
              const index = state.options.findIndex(o => o._id === payload._id);
              if (index !== -1) {
                  state.options[index] = payload;
              }
              state.isSuccessOption = true;
              state.isErrorOption = false;
          })
          
          
          .addCase(saveSetting.pending, (state) => {
            state.isFetching = true;
            // state.count=0;
            state.isError = false;
            state.isSuccessSettings = false;})
        
            .addCase(saveSetting.fulfilled, (state, { payload }) => {
              state.isFetching = false;
              const index = state.settings.findIndex(s => s._id === payload._id);
              if (index !== -1) {
                  state.settings[index] = payload;
              }
              state.isSuccessSettings = true;
              state.isError = false;
          })
            .addCase(saveSetting.rejected, (state, { payload }) => {
              state.isFetching = false;
              state.isError = true;
              state.isSuccessSettings = false;
            })
          
        
         
        }})
        export const { clearModulesData } = modulesSlice.actions;


export default modulesSlice.reducer;